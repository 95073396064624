<template>
  <div class='bg-gray-900'>
    <div v-if='showingMessageBox'
         class='absolute text-sm text-center rounded z-20'
         :class='attendingMessageBoxClass'>
      <div v-if='selectedSessionConfigAttendingMessage'
        class='p-2 lg:p-8 text-center leading-normal whitespace-pre-line'>
        {{ selectedSessionConfigAttendingMessage }}
      </div>
      <button
        class='h-12 pl-12 pr-10 rounded uppercase text-white font-semibold hover:shadow-md border border-transparent hover:border-indigo-200 flex flex-row items-center mx-auto gap-x-2'
        :style='startLiveButtonStyle'
        @click='playLiveVideo'>
        {{ selectedSessionConfigAttendingButtonText }}
        <play-icon/>
      </button>
    </div>
    <video ref='videoPlayer'
      class='video-js'
      preload='none'
      playsinline></video>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import {PlayIcon}               from '@vue-hero-icons/outline'
import 'video.js/dist/video-js.css'
import 'videojs-landscape-fullscreen'
import 'videojs-contrib-quality-levels'
import 'videojs-hls-quality-selector'
import videojs                  from 'video.js'
import dayjs                    from 'dayjs'
import WatchedVideoCacheHelpers from '@/utils/watched-video-cache-helpers'
import { createStoreon }        from 'storeon'
import { crossTab }             from '@storeon/crosstab'

export default {
  name: 'LiveVideoContent',
  props: [
    'watermarkText',
    'watermarkHelperText'
  ],
  components: {
    PlayIcon
  },
  data () {
    return {
      startedFromAutoPlay: false,
      getLiveVideoPlayedTimes: null,
      showingMessageBox: true,
      liveVideoPlayer: null,
      playStartTime: null,
      videoTracker: null,
      storeViewingVideoCount: null,
      increaseViewingVideoCount: null,
      newLiveHlsUrl: '',
      retryCount: 0,
      playedTimes: null,
    }
  },
  watch: {
    'newLiveHlsUrl': {
      handler: function (newVal, oldVal) {
        if (newVal && newVal != oldVal) {
          this.$nextTick(() => {
            if (this.liveVideoPlayer) {
              this.liveVideoPlayer.src([{type:'application/x-mpegURL', src: newVal}])
            } else {
              this.liveVideoPlayer = videojs(this.$refs.videoPlayer, this.videoOptions)
              //set quality list - 720p and 1080p
              let qualityLevels = this.liveVideoPlayer.qualityLevels()
              qualityLevels.on('addqualitylevel', (event) => {
                if (event.qualityLevel.height < 720) {
                  event.qualityLevel.enabled = false
                  qualityLevels.removeQualityLevel(event.qualityLevel)
                }
              })
              this.liveVideoPlayer.landscapeFullscreen({
                fullscreen: {
                  enterOnRotate: false,
                  exitOnRotate: false,
                  alwaysInLandscapeMode: false,
                  iOS: true
                }
              })
              this.liveVideoPlayer.hlsQualitySelector({displayCurrentQuality: true})
              this.liveVideoPlayer.on('error', this.resetLiveHlsUrl) // set 'error' event on liveVideoPlayer
              this.liveVideoPlayer.on(['waiting', 'pause'], this.stopWatchSession)
              this.liveVideoPlayer.on('playing', this.startWatchSession)
              this.liveVideoPlayer.on('fullscreenchange', this.fullscreenUpdate)
            }
            this.liveVideoPlayer.play()
          })
        }
      },
      immediate: true
    },
    'contentID': {
      handler: function (newVal, oldVal) {
        if (newVal && newVal != oldVal && !this.showingMessageBox) {
          this.getContentHlsUrl(this.contentID).then(resp => {
            this.playStartTime = dayjs().format()
            this.newLiveHlsUrl = resp
          }).catch(() => {
            this.$confirm('Cannot show the live video. Please refresh.', {
              confirmButtonText: 'Refrsh',
              cancelButtonText: 'Cancel',
              type: 'warning'
            }).then(() => {
              this.$router.go()
            }).catch(() => {
              this.$router.go()
            })
          })
        }
      }
    },
    'readyForWatermark': {
      handler: function (newVal) {
        if (newVal) {
          this.appendWaterMarkToVideoPlayer()
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters('events', [
      'eventMainThemeColor',
      'showingEventId',
    ]),
    ...mapGetters('sessions', [
      'selectedSessionCurrentContent',
      'selectedSessionConfigAttendingMessage',
      'selectedSessionConfigAttendingButtonText',
      'selectedSessionConfigAutoplay',
    ]),
    cacheKey () {
      return WatchedVideoCacheHelpers.encodeCacheKey({
        eventID: this.showingEventId,
        sessionID: this.sessionID,
        contentID: this.contentID,
        startTime: this.playStartTime
      })
    },
    trackWatchedVideoParams () {
      return {
        event_id: this.showingEventId,
        session_id: this.sessionID,
        content_id: this.contentID,
        video_player: 'videojs',
        start_time: this.playStartTime,
        played_times: this.playedTimes
      }
    },
    contentID () {
      return this.selectedSessionCurrentContent.id
    },
    sessionID () {
      return parseInt(this.$route.query.session_id)
    },
    startLiveButtonStyle () {
      return `background-color: ${this.eventMainThemeColor};`
    },
    isNotChromeBasedBrowser () {
      return navigator.userAgent.indexOf('Chrome') == -1
    },
    readyForWatermark () {
      // this means session page props appendWatermarkToVideoPlayer is true
      return this.liveVideoPlayer && (this.watermarkText || this.watermarkHelperText)
    },
    attendingMessageBoxClass () {
      return this.selectedSessionConfigAttendingMessage ? 'w-4/5 bg-gray-100 p-4 lg:p-8' : ''
    },
    videoOptions () {
      return {
        autoplay: true,
        muted: this.isNotChromeBasedBrowser && this.startedFromAutoPlay,
        controls: true,
        fill: true,
        html5: {
          vhs: {
            overrideNative: true,
            enableLowInitialPlaylist: true,
          },
          nativeAudioTracks: false,
          nativeVideoTracks: false
        },
        sources: [
          {
            src: this.newLiveHlsUrl,
            type: 'application/x-mpegURL',
          }
        ]
      }
    }
  },
  methods: {
    ...mapActions('contents', [
      'getContentHlsUrl',
    ]),
    ...mapActions('sessions', [
      'startWatchSession',
      'stopWatchSession',
    ]),
    ...mapActions('watchedVideos', [
      'trackWatchedVideo',
    ]),
    ...mapMutations([
      'setIsFullscreenVideo',
    ]),
    ...mapActions('userActions', [
      'createUserAction'
    ]),
    appendWaterMarkToVideoPlayer () {
      const videoEl = this.liveVideoPlayer.el()
      let text = this.watermarkText
      const div = document.createElement('div')
      div.classList.add('watermark-container')
      videoEl.appendChild(div)
      const div1 = document.createElement('div')
      const div2 = document.createElement('div')
      const div3 = document.createElement('div')
      const div4 = document.createElement('div')
      const div5 = document.createElement('div')
      div1.innerText = text
      div2.innerText = text
      div3.innerText = text
      div4.innerText = text
      div5.innerText = this.watermarkHelperText
      div1.classList.add('watermark-content')
      div2.classList.add('watermark-content')
      div3.classList.add('watermark-content')
      div4.classList.add('watermark-content')
      div5.classList.add('watermark-helper-content')
      div.appendChild(div1)
      div.appendChild(div2)
      div.appendChild(div3)
      div.appendChild(div4)
      div.appendChild(div5)
    },
    playLiveVideo () {
      if (this.$route.query.autoplay) {
        let query = Object.assign({}, this.$route.query)
        delete query.autoplay
        this.$router.replace({ query })
      }
      this.getContentHlsUrl(this.contentID).then(resp => {
        this.newLiveHlsUrl = resp
        this.initStoreViewingVideoCount()
        this.playStartTime = dayjs().format()
        this.showingMessageBox = false
        this.getLiveVideoPlayedTimes = setInterval(() => {
          let playedArray = []
          let playedData = document.getElementsByClassName('vjs-tech')[0].played
          for (let i = 0; i < playedData.length; i++) {
            let arr = []
            arr.push(playedData.start(i))
            arr.push(playedData.end(i))
            playedArray.push(arr)
          }
          WatchedVideoCacheHelpers.queueCache({cacheKey: this.cacheKey, playedArray: playedArray})
          this.playedTimes = playedArray //update trackWatchedVideoParams played_times every 5 seconds
        }, 5000)
        this.videoTracker = setInterval(this.submitTrackWatchedVideo, 30000)
        this.$emit('enter-live-session')
        this.createUserAction({
          userActionName: 'click_live_session_video_start_button',
          userActionData: {
            event_id: this.showingEventId,
            session_id: this.sessionID,
            content_id: this.contentID,
            video_player: 'videojs'
          }
        })
      }).catch((error) => {
        if (error.response.data.message === "You can't get a playback URL for this event because it hasn't started yet.") {
          this.$modal.show('video-not-started-modal')
        } else {
          this.$modal.show('video-troubleshoot-modal')
        }
      })
    },
    submitTrackWatchedVideo () {
      // this.liveVideoPlayer.error(3) // to trigger decode error manually
      this.trackWatchedVideo(this.trackWatchedVideoParams)
    },
    initStoreViewingVideoCount () {
      this.increaseViewingVideoCount = store => {
        store.on('@init', () => ({ viewingVideoCount: 0 }))
        store.on('increase', ({ viewingVideoCount }) => ({ viewingVideoCount: viewingVideoCount + 1 }))
      }
      this.storeViewingVideoCount = createStoreon([
        this.increaseViewingVideoCount,
        crossTab()
      ])
      this.storeViewingVideoCount.dispatch('increase')
      this.storeViewingVideoCount.on('@changed', (store) => {
        if (store.viewingVideoCount > 1) {
          if (this.liveVideoPlayer) {
            this.liveVideoPlayer.dispose()
          }
          this.$alert('You have started a video on another tab. This tab will be redirected to the programs page after 5 seconds', {
            confirmButtonText: 'Close',
            type: 'warning'
          })
          setTimeout(() => {
            window.open('Programs', '_self')
          }, 5000)
        }
      })
    },
    resetLiveHlsUrl () {
      console.error(this.liveVideoPlayer.error())
      this.retryCount += 1
      if (this.retryCount < 5) {
        this.getContentHlsUrl(this.contentID).then((resp) => {
          this.newLiveHlsUrl = resp
          this.liveVideoPlayer.src([{type:'application/x-mpegURL',
                                     src: this.newLiveHlsUrl}])
          this.liveVideoPlayer.play()
        }).catch((error) => {
          if (error.response.data.message === "You can't get a playback URL for this event because it hasn't started yet.") {
            this.$modal.show('video-not-started-modal')
          } else {
            this.$modal.show('video-troubleshoot-modal')
          }
        })
      } else {
        this.$modal.show('video-troubleshoot-modal')
      }
    },
    fullscreenUpdate () {
      this.setIsFullscreenVideo(this.liveVideoPlayer.isFullscreen())
    },
  },
  beforeDestroy () {
    clearInterval(this.getLiveVideoPlayedTimes)
    clearInterval(this.videoTracker)
    this.stopWatchSession()
    if (this.liveVideoPlayer) {
      this.liveVideoPlayer.dispose()
    }
  },
  mounted () {
    if (this.$route.query.autoplay && this.selectedSessionConfigAutoplay) {
      this.startedFromAutoPlay = true
      this.playLiveVideo()
    }
  }
}
</script>

<style type="text/css">
.vjs-play-control, 
.vjs-remaining-time, 
.vjs-progress-holder, 
.vjs-audio-button, 
.vjs-big-play-button, 
.vjs-subs-caps-button, 
.vjs-picture-in-picture-control {
  display: none !important;
}

.video-js .vjs-tech {
  pointer-events: none;
}

div.watermark-helper-content {
  position: absolute;
  opacity: 0.5;
  text-shadow: 2px 2px #00000055;
  bottom: 0%;
  @apply w-full font-semibold text-xs lg:text-sm text-center;
}

div.watermark-content {
  position: absolute;
  opacity: 0.5;
  text-shadow: 2px 2px #00000055;
  transform: rotate(-45deg);
  @apply w-full font-semibold text-xs lg:text-sm text-center;
}

div.watermark-container {
  width: 100%;
  height: 90%;
  position: relative;
  overflow: hidden;
}

div.watermark-content:nth-child(odd) {
  top: calc(25%);
}

div.watermark-content:nth-child(1),
div.watermark-content:nth-child(2) {
  left: calc(25%);
}

div.watermark-content:nth-child(even) {
  top: calc(70%);
}

div.watermark-content:nth-child(3),
div.watermark-content:nth-child(4) {
  right: calc(25%);
}
</style>
